import React, { Suspense, useEffect, useState } from 'react';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import "../Css_for_components/Material_page.css";
import AccessTimeSharpIcon from '@mui/icons-material/AccessTimeSharp';
import PersonIcon from '@mui/icons-material/Person';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import Alert_Modals from '../components/Alert_Modals';
import { ArrowRight } from '@mui/icons-material';
import { get_all_materials } from '../Admin/Functions/admin_functions';
import api from '../Apis/api';
import { CircularProgress } from '@mui/material';


function Materials_page() {
    const [account, setAccount] = useState(JSON.parse(localStorage.getItem("Member_account")) || null);
    const [allMaterial, setMaterial] = useState(null);
    const [catagorys, setCatagory] = useState(null);
    const [loadingCatagorys, setLoadingCatagorys] = useState(true);
    const [loadingMaterials, setLoadingMaterials] = useState(true);
    const [alert_dialog, setDialog] = useState(false);
    const [alert_text, setText] = useState("");
    const [alert_type, setType] = useState();

    const get_catgory = async () => {
        try {
            const response = await api.get("/Material/get-all/catagory");
            if (response.data.status === "success") {
                setCatagory(response.data.catagorys);
            }
        } catch (error) {
            console.log(error);
            setText("Internal server error while loading catagory");
            setType("error");
            setDialog(true);
        } finally {
            setLoadingCatagorys(false);
        }
    };

    const handel_catagory_selection = async (cat_id) => {
        setLoadingMaterials(true);
        try {
            const response = await api.get(`/Material/get/${cat_id}`);
            if (response.data.status === "success") {
                setMaterial(response.data.result > 0 ? response.data.material : null);
            }
        } catch (error) {
            console.log(error);
            setText("Internal server error while loading catagory");
            setType("error");
            setDialog(true);
        } finally {
            setLoadingMaterials(false);
        }
    };

    const navigate = useNavigate();
    useEffect(() => {
        if (account === null) {
            navigate("/Loginpage");
        } else {
            get_all_materials()
                .then((res) => {
                    if (res.status === "success") {
                        setMaterial(res.material);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    setText("Error while fetching materials");
                    setType("error");
                    setDialog(true);
                })
                .finally(() => {
                    setLoadingMaterials(false);
                });

            get_catgory();
        }
    }, [account, navigate]);

    return (
        <div className='container-fluid p-0'>
            <div className='row'>
                <div className='col-sm-12 p-5 about_us_page_title_container'>
                    <div className='d-flex'>
                        <h5>Home</h5><ArrowRight /><h5 className='text-secondary'>Materials</h5>
                    </div>
                    <div className='mt-3'>
                        <h2>Materials</h2>
                    </div>
                </div>
            </div>
            <div className='container'>
                <div className='row'>
                    <div className='col-sm-12 col-lg-3 py-lg-5 p-sm-2'>
                        {alert_dialog && <Alert_Modals type={alert_type} text={alert_text} />}
                        <div className='h-100 matarial_catagory_container shadow p-lg-3 px-2 rounded'>
                            <h6 className='text-primary w-100 text-center mt-3 fw-bolder'>You Can select a Catagory from below</h6>
                            {loadingCatagorys ? (
                                <div className="loading-container">
                                    <CircularProgress className="large-spinner" color="inherit" />
                                </div>
                            ) : (
                                catagorys && catagorys.map((catagory, index) => (
                                    <div key={index} onClick={() => handel_catagory_selection(catagory.catagory_id)}>
                                        <h6>{catagory.catagory_name}</h6>
                                    </div>
                                ))
                            )}
                        </div>
                    </div>
                    <div className='col-sm-12 col-lg-9'>
                        {loadingMaterials ? (
                            <div className="loading-container">
                                <CircularProgress className="large-spinner" color="inherit" />
                            </div>
                        ) : allMaterial ? (
                            allMaterial.map((material, index) => (
                                <div key={index} className='container p-lg-2 p-sm-2 mt-sm-2'>
                                    <div className='container-fluid px-lg-2'>
                                        <div className='row'>
                                            <div className='col-sm-12 p-2'>
                                                <div className='material_contianer p-2 p-lg-3 shadow rounded'>
                                                    <div className='download_button_container px-lg-3'>
                                                        <a target="_blank" href={`https://dev-eeva-server.ethiopianevaluationassociation.org/Materials/${material.file_path}`} download="Material">
                                                            <CloudDownloadIcon className="download_button" />
                                                        </a>
                                                        <h5 className='download_text w-100 text-center'>Download</h5>
                                                    </div>
                                                    <div className='material_info p-1 px-lg-3'>
                                                        <h4><span className='fw-bold'>Title: </span>{material.title}</h4>
                                                        <h5><span className='fw-bold'>Catagory: </span>{material.catagory_name}</h5>
                                                        <div className="material_content" dangerouslySetInnerHTML={{ __html: material.description }} />
                                                        <div className='material_details mt-3'>
                                                            <div>
                                                                <PersonIcon className="blog_details_icons" />
                                                                <h6 className='text-secondary'>{material.username}</h6>
                                                            </div>
                                                            <div>
                                                                <AccessTimeSharpIcon className="blog_details_icons" />
                                                                <h6 className='text-secondary'>
                                                                    {moment(material.upload_date).format('YYYY-MM-DD')}
                                                                </h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <div className='mt-5'>
                                <Alert_Modals type="warning" className="mt-4" text="Sorry no Material found on the system!" />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Materials_page;
