import React, { useEffect, useState } from 'react'
import  "../Css_for_components/Pricing.css";
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';

function Pricing() {
    useEffect(()=>{
        const options={
            rootMargin:"0px",
            threshold:0.2
        }
     const title=document.getElementById("pricing_card_title");
     const style=document.getElementById("pricing_details");
     const observer=new IntersectionObserver((enteries)=>{
        enteries.forEach((entry)=>{
            if(entry.isIntersecting)
                { 
                    
                if(entry.target.id=='pricing_card_title')
               {
                entry.target.classList.add("pricing_fade_in");
                // observer.unobserve(entry.target);
                }
                else if (entry.target.id=="pricing_details")
                {
                    entry.target.classList.add("pricing_fade_in");
                }
                // observer.unobserve(entry.target);
                }
        })
      
       
    },options)
    observer.observe(title);
    observer.observe(style);
    },[])
  return (
    
    <div className='container-fluid  rounded mt-4'>
            <div className='container pricing_title_hidden  'id='pricing_card_title'>
                <h5 className='pricing_card_title' >PRICING</h5>
                <div className=" row mt-2 p-2 ">
                <p className='text-secondary text-center'>
                Join the Ethiopian Evaluation Association today and become part of a growing community of professionals committed to advancing the field of evaluation in Ethiopia. As a member, you’ll gain access to exclusive resources, networking opportunities, professional development, and events designed to enhance your skills and impact. Our membership packages are tailored to suit both individuals and organizations, with affordable rates that ensure inclusivity and access for all. Be part of shaping the future of evaluation in Ethiopia by becoming a valued member of our association.                     </p>

                </div>
            </div>
            <div className='container-fluid p-lg-2  pricing_hidden' id="pricing_details">
               
            <div className='row mt-4'>
                <div className='col-sm-12 col-lg-4  mx-auto p-3'>
                    <div className='d-flex flex-column rounded shadow pricing_container  '>
                            <div className='bg-light p-3'>
                                <h5 className='pricing_titles'>REGULAR/ORDINARY MEMBERS</h5>
                            </div>
                            <div className='pricing_details_container'>
                               <div className='d-flex'>
                               <span className='currency_sign mx-2' >Birr</span>
                               <h5 className=''><span className='text-primary fw-bolder mx-2' style={{fontSize:"50px"}}>500</span> / Year</h5>
                              
                               </div>
                               <ul>
                               <li>
                                <h4> Priority participation in trainings, workshops, and scientific sessions.</h4>
                               </li>
                               <li>
                                <h4>Access to online resources for career growth </h4>
                                </li>
                                <li>
                               
                                <h4>Free job postings via EEvA’s Career Center and social platforms</h4>
                                </li>
                                <li>
                              
                                <h4>Right to vote and be elected to the board.</h4>
                                </li>
                               </ul>
                            </div>
                            <div className='bg-light p-3'>

                            </div>
                    </div>
                </div>
                <div className='col-sm-12 col-lg-4 mx-auto p-3'>
                    <div className='d-flex flex-column rounded shadow pricing_container  '>
                            <div className='bg-light p-3'>
                                <h5 className='pricing_titles'>Life Time Members</h5>
                            </div>
                            <div className='pricing_details_container'>
                               <div className='d-flex'>
                               <span className='currency_sign mx-2' >Birr</span>
                               <h5 className=''><span className='text-primary fw-bolder mx-2' style={{fontSize:"50px"}}>3000</span> / <AllInclusiveIcon/></h5>
                              
                               </div>
                               <ul>
                               <li>
                                <h4> Priority participation in trainings, workshops, and scientific sessions.</h4>
                               </li>
                               <li >
                                <h4>Access to online resources for career growth </h4>
                               </li>
                               <li >
                                <h4>Free job postings via EEvA’s Career Center and social platforms</h4>
                               </li>
                               <li >
                                <h4>Right to vote and be elected to the board.</h4>
                               </li>
                               </ul>
                            </div>
                            <div className='bg-light p-3'>

                            </div>
                    </div>
                </div>
                <div className='col-sm-12 col-lg-4 mx-auto p-3'>
                    <div className='d-flex flex-column rounded shadow pricing_container  '>
                            <div className='bg-light p-3'>
                                <h5 className='pricing_titles'>Institutional Members</h5>
                            </div>
                            <div className='pricing_details_container'>
                               <div className='d-flex'>
                               <span className='currency_sign mx-2' >Birr</span>
                               <h5 className=''><span className='text-primary fw-bolder mx-2' style={{fontSize:"50px"}}>3000</span> / Year</h5>
                              
                               </div>
                               <ul>
                               <li >
                                <h4>Representative participates in Annual Conference </h4>
                               </li>
                               <li >
                                <h4>Request membership and recommendation letters.</h4>
                               </li>
                               <li >
                                <h4>Access career advancement resources.</h4>
                               </li>
                               <li >
                                <h4>Right to vote and join the board.</h4>
                               </li>
                               </ul>
                            </div>
                            <div className='bg-light p-3'>

                            </div>
                    </div>
                </div>
            </div>
            </div>
           
    </div>
  )
}

export default Pricing