import React, { useEffect,useState } from 'react'
import { ArrowRight } from '@mui/icons-material';
import "../Css_for_components/members.css";
import { Link } from 'react-router-dom';
import { get_all_members } from '../Admin/Functions/admin_functions';
import { get_all_board_memebers } from '../functions/api_call_functions';
function Members() {
    const [members,setMember]=useState(null);
    const roleOrder = ["President", "Vice President"," Treasurer and Communication Director" ,"Secretary","Chair Standardization and Documentation","Logistics Manager","Chair Capacity Building","Bored Advisory"];
    useEffect(()=>{
        get_all_board_memebers().then((res)=>{
         
            const sortedRoles =res.members && res.members.sort((a, b) => roleOrder.indexOf(a.org_role) - roleOrder.indexOf(b.org_role));

            setMember(sortedRoles);
        })
    },[])
  return (
    <div className='container-fluid p-0'>
                <div className='row'>
            <div className='col-sm-12 p-5 about_us_page_title_container'>
                <div className='d-flex'>
                    <h5>Home</h5><ArrowRight/><h5 className='text-secondary'>Members</h5>
                </div>
                <div className='mt-3'>
                    <h2>Take a look at our Board Members</h2>
                </div>
            </div>
        </div>

        <div className='container p-3'>
            <div className='row'>
                {
                    members!==null?(
                        members.map((data)=>{
                            return(
                                <>
                                <div className='col-sm-12 col-lg-4 mt-3  mx-auto'>
                    <div className='single_container rounded mx-auto'>
                    <div className='members_detail'>
                            <h5 className=''> {data.firstname} {data.lastname}</h5>
                            <h6 className=''> {data.org_role} </h6>
                        </div>
                        <div className='members_profile_container'>
                            <img loading='lazy' src={`https://dev-eeva-server.ethiopianevaluationassociation.org/Profile/${data.profile_image}`} className='img-fluid rounded' style={{maxHeight:"250px"}} alt=''/>
                        </div>
                       <div className='w-100 p-2 flex-right'>
                            <Link to={`/Members/Profile-view/${data.username}`}><button className='see-profile-button rounded'>See Profile</button></Link>
                       </div>
                    </div>
                </div>
                                </>
                            )
                        })
                    ):(<>
                     <div className="alert alert-warning alert-dismissible visible">
        <button type="button" class="btn-close" data-bs-dismiss="alert"></button>
              <strong>Sorry!</strong> <h6 className='text-success'>NO Members registered on the System yet</h6>
          </div>
                        </>)
                }
            
                
            </div>
        </div>
    </div>
  )
}

export default Members