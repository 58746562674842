import React,{useState,useEffect} from 'react'
import TitleHeader from './TitleHeader';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import Alert_Modals from '../../components/Alert_Modals';
import { useSelector,useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { generateRandomString, update_org_role } from '../Functions/admin_functions';
import { check_id_status, get_a_member_transaction } from '../../functions/api_call_functions';
import { data } from 'jquery';
import api from '../../Apis/api';
function MemberSetting() {
    const [alert_dialog,setDialog]=useState(false);
    const[alert_text,setText]=useState("");
    const[alert_type,setType]=useState();
    const navigate=useNavigate();
    const [member_data,setData]=useState(useSelector((state)=>state.admin_reducer.selected_member));
    const [idStatus,setStatus]=useState(true)

    const [account, setAccount]= useState((JSON.parse(localStorage.getItem("Admin_user_account")))||null);
    
    const [role,setRole]=useState({
        org_role:"",
        expire_date:""

    })
    
    const handel_generate_id=()=>{
        navigate(`/Admin/Id/${member_data.username}`);
    }
    const onHandelChange=(e)=>{
        setRole( (prev)=>({
            ...prev,
            [e.target.name]:e.target.value
          }))
    }
    const onhandelSubmit=(e)=>{
        e.preventDefault(); 
        
        update_org_role(member_data.account_id,role.org_role,role.expire_date).then((res)=>{
            if(res.status=="success" && res.result>0)
            {
                setText("Members Organization role has been updated");
                setType("success");
                setDialog(true);
                setTimeout(() => {
                    setDialog(false);
                   
                  }, 1500);
            }
            else{
                setText("Internal server error while update role");
                setType("error");
                setDialog(true);
                setTimeout(() => {
                    setDialog(false);
                  
                   
                  }, 1500);
            }
        })
    }
    useEffect(()=>{
        if (account==null)
          {
              navigate("/Admin/login");
          }
          else if(member_data==null){
            navigate("/Admin/Manage-memebership");
          }
         
          setRole( {
            org_role:member_data?.org_role,
            expire_date: moment(member_data?.membership_enddate).format('YYYY-MM-DD')
          })
         
          check_id_status(member_data?.account_id).then((res)=>{
            if (res.status=="success"){
                if(res.result==1)
                {setStatus(false);}
            }
           })
          
         
      },[member_data])
      const handel_id_request =async()=>{
       try {
        const response= await api.post("/ID/create/request",{
            account_id:member_data?.account_id,
            request_id:generateRandomString()
        })
        if(response.data.status=="success"){
        setText("Request submitted");
        setType("success");
        setDialog(true);
        setTimeout(() => {
            setDialog(false);
             }, 1000);
        }
       } catch (error) {
        console.log(error);
        setText(error.response.data.error);
        setType("error");
        setDialog(true);
        setTimeout(() => {
            setDialog(false);
             }, 1000);
       }

      }
      
  return (
    <div className='container-fluid'>
            <div className='container-fluid whole_title_container shadow '>
    <TitleHeader icon={<SettingsSuggestIcon/>} title="Member Configuration"/>
    </div>
                <div className='row mt-4'>
                    <div className='my-3'>
                    <button className='btn btn-success fw-bold' disabled={idStatus}onClick ={()=>handel_generate_id()}>Generate ID</button>

                    </div>
                    <div className='my-3'>
                    <button className='btn btn-success fw-bold' onClick ={()=>handel_id_request()}>Request ID</button>

                    </div>
                <div className='col-sm-12 col-lg-8 bg-white rounded mx-auto'>
                    
                        <div className='row'>
                        <div className="col-sm-12 col-lg-12 p-3">
                        <div className='row p-3'>
                                  <h5 className='shadow p-3 rounded '>Member :{member_data?.username}</h5>
                                </div>  
                            <form onSubmit={onhandelSubmit} >

                            {alert_dialog && <Alert_Modals type={alert_type} text={alert_text}/>}

                            <div className="form-floating mb-3 mt-3">
                                <select className="form-control" onChange={onHandelChange} required value={role.org_role}name='org_role'>
                                    <option value="">Please select Organzation Role</option>
                                    <option value="Member">Member</option>
                                    <option value="President">President</option>
                                    <option value="Vice President">Vice President</option>
                                    <option value="Secretary">Secretary</option>
                                    <option value="Treasurer and Communication Director">Treasurer and Communication Director</option>
                                    <option value="Chair Standardization and Documentation">Chair Standardization and Documentation</option>
                                    <option value="Logistics Manager">Logistics Manager</option>
                                    <option value="Chair Capacity Building">Chair Capacity Building</option>
                                    <option value="Bored Advisory">Bored Advisory</option>

                                </select>
                           
                                <label htmlFor="Gender">Please select Organzation Role</label>
                                </div>
                                <div  className="form-floating mb-3 mt-3">
                                <input type="date" className="form-control" value={role.expire_date} onChange={onHandelChange} name="expire_date" />
                                <label htmlFor="Gender">Membership Expire Date</label>

                                </div>  
                                <div className='row p-3'>
                                    <button type='submit'  className='btn btn-success fw-bold' >
                                        Update role
                                        </button>
                                </div>  
                            </form>
                         </div>
                        </div>
                
                    </div>
                </div>
       

      
    </div>
  )
}

export default MemberSetting