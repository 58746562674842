import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';           // Core Swiper styles
import 'swiper/css/navigation'; // Navigation (arrows)
import 'swiper/css/pagination'; // Pagination (bullets)
import '../Css_for_components/partnercomponent.css';

function Partnerlogos() {
 // List of partner logos
const partnerLogos = [
    { id: 1, src: '../Assets/Agora.png', alt: 'Partner 1' },
    { id: 2, src: '../Assets/AfREA.png', alt: 'Partner 2' },
    { id: 3, src: '../Assets/Mekelle Uni.png', alt: 'Partner 3' },
    { id: 4, src: '../Assets/ICED.svg', alt: 'Partner 4' },
    { id: 5, src: '../Assets/UN-Women.svg', alt: 'Partner 5' },
    { id: 6, src: '../Assets/1.png', alt: 'Partner 6' },
    { id: 8, src: '../Assets/5.jpg', alt: 'Partner 8' },
    { id :9,src: '../Assets/cubeAddis.jpg', alt: 'Partner 10' }
  ];
    const [activeIndex, setActiveIndex] = useState(0);
  
    return (
        <div className='container-fluid  rounded mt-4 mb-5'>
        <div className='container   'id='pricing_card_title'>
            <h5 className='pricing_card_title' >Our Partners</h5>
            <div className=" row mt-2 p-2 ">
            <p className='text-secondary text-center'> 
            We are proud to collaborate with a diverse group of esteemed partners who share our vision of innovation and impact. These partnerships are integral to our mission, allowing us to combine strengths and expertise to achieve meaningful progress. Together, we strive to create lasting change and drive forward-thinking solutions that benefit communities and industries alike. Below are some of our valued partners who help us push boundaries and make a difference.                </p>

            </div>
        </div>
      <div className="partner-slider-container mt-5">
        <Swiper
          spaceBetween={20} // Space between logos
          slidesPerView={1} // Default for mobile
          breakpoints={{
            768: { // For tablet and larger
              slidesPerView: 2,
            },
            1024: { // For desktop and larger
              slidesPerView: 4,
            },
          }}
          navigation // Enables previous/next buttons
          pagination={{ clickable: true }} // Enables pagination bullets
          modules={[Navigation, Pagination]}
          onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)}
        >
          {partnerLogos.map((logo) => (
            <SwiperSlide key={logo.id}>
              <img
                src={logo.src}
                alt={logo.alt}
                className="partner-logo w-full h-auto"
                style={{
                  maxWidth: '150px', // Adjust as needed
                  margin: '0 auto',
                }}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      </div>
    );

}

export default Partnerlogos