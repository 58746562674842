// const for getting all member data with account ID
import api from "../Apis/api";
export const get_member_account=async(account_id,token)=>
{   
    try {
        const response =await api.get(`/get-member/${account_id}`)
        return({
            status:response.data.status,
            user:response.data.user,
            result:response.data.result
        })

    } catch (error) {
        console.log(error);
        return({
            status:"fail",
            error:error.response.data.error
        })
    }
}

export const get_a_memeber=async(Username)=>
{   
    try {
        const response =await api.get(`/get-member/data/${Username}`)
        return({
            status:response.data.status,
            user:response.data.user,
            result:response.data.result
        })

    } catch (error) {
        console.log(error);
        return({
            status:"fail",
            error:error.response.data.error
        })
    }
}

export const check_oldpassword =async(account_id,password)=>
{
    try{
        const response=await api.post(`/member/match-password/check`,{
            account_id,
            password
        });
        return response.data;
    }catch(error)
    {
        console.log(error);
        return({
            status:"fail",
            error:error.response.data.error
        })
    }
}

export const update_password =async(account_id,password)=>
{
    try{

        const response=await api.put(`/member/update-password/`,
        {
            account_id,
            password
        });
        return response.data;
    }catch(error)
    {
        console.log(error);
        return({
            status:"fail",
            error:error.response.data.error
        })
    }
}
export const get_blogs_active= async(status)=>{
   
    try{
        const response= await api.get(`/Blog/get-all/${status}`);
        return({
            status:response.data.status,
            Blog:response.data.Blog,
            result:response.data.result
        })
    }catch(error){
        console.log(error);
        return({
            status:"fail",
            error:error.response.data.error
        })
    }
 }
 export const get_blogs_recent= async(status)=>{
   
    try{
        const response= await api.get(`/Blog/get-recent/${status}`);
        return({
            status:response.data.status,
            Blog:response.data.Blog,
            result:response.data.result
        })
    }catch(error){
        console.log(error);
        return({
            status:"fail",
            error:error.response.data.error
        })
    }
 }
 export const get_ablog= async(blog_id)=>{

    try {
        const response= await api.get(`/Blog/get/${blog_id}`);
        return({
            status:response.data.status,
            Blog:response.data.Blog,
            result:response.data.result
        })
    } catch (error) {
        console.log(error);
        return({
            status:"fail",
            error:error.response.data.error
        })
    }
 }
//seartch for a bloag
 export const search_ablog= async(blog_title)=>{

    try {
        const response= await api.post(`/Blog/search/`,{
            blog_title
        });
        // console.log(response);
        return({
            status:response.data.status,
            Blog:response.data.Blog,
            result:response.data.result
        })
    } catch (error) {
        console.log(error);
        return({
            status:"fail",
            error:error.response.data.error
        })
    }
 }

 export const check_existing_request=async(account_id)=>{
    try {
        const response= await api.get(`/Request/get/a/${account_id}`);
        return({
            status:response.data.status,
            request:response.data.request,
            result:response.data.result
        })
    } catch (error) {
        console.log(error);
        return({
            status:"fail",
            error:error.response.data.error
        })
    }
 }
 export const get_a_member_transaction=async(account_id)=>{

    try {
        const response= await api.get(`/transaction/get/a/${account_id}`);
        return({
            status:response.data.status,
            transaction:response.data.transaction,
            result:response.data.result
        })
    } catch (error) {
        console.error( error);
        return({
            status:"fail",
            error:error.response.data.error
        })
    }
 }
 export const get_all_board_memebers= async()=>{
    try {
        const response= await api.get(`/get/all-board/members`);
        return({
            status:response.data.status,
            members:response.data.member,
            
        })
    } catch (error) {
        console.error( error);
        return({
            status:"fail",
            error:error.response.data.error
        })
    }
 }
 export const check_id_status=async(account_id)=>{
    try{
        const response=await api.get (`/get/check-id/status/${account_id}`);
        return(
            {
                status:response.data.status,
                result:response.data.result,
                request:response.data.request
            }
        )

    }catch (error){
        console.error(error);
        return({
            status:"fail",
            error:error.response.data.error
        })
    }
 }