import React,{useState} from 'react'
import MUIDataTable from "mui-datatables";
import { useSelector,useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import api from '../../Apis/api';
import Alert_Modals from '../../components/Alert_Modals';
import { Download } from '@mui/icons-material';
function Material_ViewTable({result,refresh_data}) {
    const [alert_dialog,setDialog]=useState(false)
    const[alert_text,setText]=useState("");
    const[alert_type,setType]=useState();
    const options = {
        filterType: 'checkbox',
        pagination:true,
        responsive:'stacked',
        rowsPerPage:5,
        rowsPerPageOptions:[5,10,15,20],
        rowHover:true,
        selectableRowsHeader:false,
        selectableRows:false
      };
      const handel_delete=async(material_id)=>{
        if( window.confirm("Are you sure you want to remove this Material?"))
        {try {
         const response= await api.delete(`/Material/Delete/${material_id}`)
         if(response.data.status=="success")
         {
             setDialog(true);
             setType("success")
             setText("Material has been successfuly removed")
             setTimeout(() => {
                 setDialog(false);
                 refresh_data()
               }, 1500);
         }
        } catch (error) {
            console.log(error);
            setDialog(true);
            setType("error");
            setText("Internal Server Error occured while trying to remove the Material")
            setTimeout(() => {
             setDialog(false);
             refresh_data()
           }, 1500);
         }}

      }
      const columns=[
        {label:"Catagory name",name:"catagory_name"},
        {label:"Title",name:"title"},
        {label:"Description",name:"description", options:{filter: false}},
        {label:"File",name:"file_path",options:{
          filter: false,
            customBodyRender:(value)=>(
                value!==null ?<a target="_blank" href={`https://dev-eeva-server.ethiopianevaluationassociation.org/Materials/${value}`} Download="Material">{`${value}`}</a>:""
            )
        }},
        {label:"Uploaded By",name:"username"},
        {label:"Action",name:"material_id", options:{
          filter: false,
            customBodyRender:(value)=>(
               
                   <>
                    <span className='btn btn-danger mx-1 my-1'onClick ={()=>handel_delete(value)}><DeleteIcon/></span>
                   </>
               
            )
    }},]
    if(  result!== undefined && result !== null )  { 
        return (
            <>
            
          {alert_dialog && <Alert_Modals type={alert_type} text={alert_text}/>}
          <MUIDataTable 
          title={"All Material"}
          data={result}
          columns={columns}
          options={options}
          className="text-color"
        /></>
        )}
        else
        {
          return (
          <Alert_Modals type="error" 
          text="Sorry no Material found on the system !"
            />
            )
          }
      }


export default Material_ViewTable