import React,{useState,useEffect} from 'react'
import { convert_to_date } from '../functions/member_functions';
import LinkedIn from '@mui/icons-material/LinkedIn'
import { get_a_memeber } from '../functions/api_call_functions';
import { useParams } from 'react-router-dom';
function Member_detail_page() {
    const {username}=useParams();
    
    const [user_info,setInfo]=useState({
        account_id:"",
        firstname:"",
        lastname:"",
        gender:null,
        title:null,
        email:"",
        profile_pic:null,
        username:"",
        dob:"",
        bio:"",
        address:"",
        experience:"",
        education_level:"",
        profession:"",
        Linkedin_link:"",
        phonenumber:"",
        role:"",
        status:"",
        end_date:"",
      
  
    });
    useEffect(()=>{
        get_a_memeber(username).then((res)=>{
            setInfo({
                account_id:res.user.account_id,
                firstname:res.user.firstname,
                lastname:res.user.lastname,
                gender:res.user.gender,
                title: res.user.title,
                email:res.user.email,
                username:res.user.username,
                img_location:res.user.profile_image,
                phonenumber:res.user.phonenumber,
                role:res.user.role,
                image_profile:res.user.profile_image,
                dob:convert_to_date(res.user.dob),
                bio:res.user.bio,
                address:res.user.address,
                experience:res.user.experience,
                education_level:res.user.education_level,
                profession:res.user.profession,
                Linkedin_link:res.user.Linkedin_link,
                profile_pic:null,
                status:res.user.membership_status,
                end_date:convert_to_date(res.user.membership_enddate),
               
               })
        })
    },[])
  return (
    <div className='container-fluid '>
        <div className='container '>
            <div className='row'>
                 <div className='col-sm-12 col-lg-8 mx-auto'>
                 <div className='profile_update_container rounded shadow my-3'>
                        <div className='profile_image_container'>
                            <img src={user_info.image_profile?`https://dev-eeva-server.ethiopianevaluationassociation.org/Profile/${user_info.image_profile}`:user_info.profile_pic? URL.createObjectURL(user_info.profile_pic):"/Assets/blank-profile-picture.webp"} className='img-fluid rounded-circle' alt='profile_pic'/>
                            
                        </div>
                        <div className='profile_info_container '>
                        <div className='container ' >
                             <div class="row">
                             <div class="col-sm-12" className='text-justify' dangerouslySetInnerHTML={{__html:user_info.bio}}>
                               
                                </div>
                                </div>
                            </div>
                            <div>
                                <h6> First Name :<span>{user_info.firstname}</span></h6>
                            </div>
                            <div>
                                <h6> Last Name :<span>{user_info.lastname}</span></h6>
                            </div>
                            <div>
                                <h6> Gender :<span>{user_info.gender}</span></h6>
                            </div>
                            <div>
                                <h6> Title :<span>{user_info.title}</span></h6>
                            </div>
                           
                            <div>
                                <h6> Email :<span>{user_info.email}</span></h6>
                            </div>
                            <div>
                                <h6> Education level :<span>{user_info.education_level}</span></h6>
                            </div>
                            <div>
                                <h6> Profession :<span>{user_info.profession}</span></h6>
                            </div>
                            <div>
                                <h6> Experience :<span>{user_info.experience}</span></h6>
                            </div>
                            <div>
                                <a href={user_info.Linkedin_link} style={{textDecoration:"none"}} target='_blank'> <LinkedIn/></a>
                            </div>
                          
                        </div>
                    </div>
            </div>
            </div>

           
        </div>

    </div>
  )
}

export default Member_detail_page