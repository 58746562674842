import React,{useState,useEffect,useRef} from 'react'
import "../css/Id_card.css";
import QRCode from "react-qr-code";
import { useReactToPrint } from 'react-to-print';
import { useNavigate } from 'react-router-dom';
import Alert_Modals from '../../components/Alert_Modals';
import PrintIcon from '@mui/icons-material/Print';
import moment from 'moment';
import { useSelector } from 'react-redux';
function Id_card() {
   
    const [member_data,setData]=useState(useSelector((state)=>state.admin_reducer.selected_member));
    const [account, setAccount]= useState((JSON.parse(localStorage.getItem("Admin_user_account")))||null);
    const navigate= useNavigate();
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        onAfterPrint:()=>{
        
         navigate("/Admin/dashbord");
      }
      });
    
    useEffect(()=>{
        if (account==null)
          {
              navigate("/Admin/login");
          }
          else if(member_data==null){
            navigate("/Admin/Manage-memebership");
          }
        
         
      },[])
  return (
    <div className='container-fluid'>
        <div className='container p-lg-5'>
        <div className='row'>
            <div className='col-sm-12 col-lg-12 '>
                <button className='btn btn-success' onClick={handlePrint}><PrintIcon/></button>
                <div className='id_container' ref={componentRef}>
                    <div className='top_Id_container '>
                    <div className="id_logo_container">
                        <img src='/Assets/EEVA_logo.png'  alt= "logo" className='id_logo'/>
                    </div>
                    <div className='top_id_title_container'>
                        <h5>EEvA Membership ID Card</h5>
                    </div>
                    </div>
                    <div className='middle_ID_container'>
                       <div className='ID_profile_container'>
                            <img src={ `https://dev-eeva-server.ethiopianevaluationassociation.org/Profile/${member_data?.profile_image}`} alt='profile_pic' className='id_profile_pic img-fluid  rounded'/>
                            <div className='w-100 h-100 mt-2  p-1'>
                            <QRCode
                                style={{ height: "70px", maxWidth: "70px", width: "100px" }}
                                value={`https://ethiopianevaluationassociation.org/Members/Profile-view/${member_data.username}`}
                                viewBox={`0 0 256 256`}
                                className='mx-auto'
                                />
                            </div>
                        </div> 
                        <div className='Id_detail_contianer'>
                            <div className=''>
                                <h6 className='Id_content_text'>Name:</h6>    
                                <h6 className='Id_content_text'>{member_data?.firstname}</h6>
                                <h6 className='Id_content_text'>{member_data?.lastname}</h6>   
                            </div>
                            <div className=''>
                                <div>
                                <h6 className='Id_content_text'>Title:</h6>   
                                <h6 className='Id_content_text'>{member_data?.title}</h6>   
                                </div>
                                <div>
                                <h6 className='Id_content_text'>Gender:</h6>   
                                <h6 className='Id_content_text'>{member_data?.gender}</h6>   
                                </div>  
                            </div>
                            <div className=''>
                                <h6 className='Id_content_text'>Phonenumber:</h6>    
                                <h6 className='Id_content_text'>{member_data?.phonenumber}</h6>
                                
                            </div>
                            <div className=''>
                                <h6 className='Id_content_text'>Email:</h6>    
                                <h6 className='Id_content_text'>{member_data?.email}</h6>   
                            </div>
                            <div className=''>
                                <h6 className='Id_content_text'>Joining Date:</h6>    
                                <h6 className='Id_content_text'>{moment(member_data?.created_date ).format('YYYY-MM-DD')}</h6>
                            </div>
                        </div>    
                    </div>
                    <div className='bottom_Id_container'>

                    </div>
                    
                </div>

            </div>

        </div>
        </div>
      
    </div>
  )
}

export default Id_card