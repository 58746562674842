import React, { useEffect,useState } from 'react'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import PlaceIcon from '@mui/icons-material/Place';
import  "../Css_for_components/homepage_footer.css";
import EmailIcon from '@mui/icons-material/Email';
import { Phone, Telegram } from '@mui/icons-material';
import InstagramIcon from '@mui/icons-material/Instagram';
import XIcon from '@mui/icons-material/X';
import { check_site_config } from '../Admin/Functions/admin_functions';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import CopyrightIcon from '@mui/icons-material/Copyright';
import { Link } from 'react-router-dom';

function HomePage_footer() {
    const [siteConfig,setInfo]=useState({

        primary_email:"",
        secondary_email:"",
        primary_phonenumber:"",
        secondary_phonenumber:"",
        Address:"",
        Description:"",
        twitter_link:"",
        facebook_link:"",
        telegram_link:"",
        instagram_link:"",
        Likedin_link:""
        
    })
    useEffect(()=>{
        check_site_config().then((res)=>{
            if(res.result>0){
              const info= res.configuration[0];
              setInfo({
               primary_email:info.primary_email,
               secondary_email:info.secondary_email,
               primary_phonenumber:info.primary_phonenumber,
               secondary_phonenumber:info.secondary_phonenumber,
               Address:info.Address,
               Description:info.Description,
               twitter_link:info.twitter_link,
               facebook_link:info.facebook_link,
               telegram_link:info.telegram_link,
               instagram_link:info.instagram_link,
               Likedin_link:info.Likedin_link,
               id:info.id,
              })
            }
         
          }) 
    },[])
  return (
    <>
        <div className='continer-fluid footer_container'>
        <div className='row'>
        <div className='col-sm-12 col-md-6 p-2'>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-sm-12 '>
                    <img src='/Assets/EEVA_logo.png' alt= "logo" className='footer_logo rounded img-fluid'/>
                    <div className='footer_first_section'>
                        <h4>About EEvA</h4>
                        <p dangerouslySetInnerHTML={{__html:siteConfig.Description}}></p>
                    </div> 
                    <div className='social_media_container'>
                        <div className='social_media_icon_container'>
                          {
                          siteConfig.twitter_link==""?(<a  href="#"><XIcon /></a> ):(<a  target="_blank" href={siteConfig.twitter_link}><XIcon /></a> )
                          } 
                        </div>
                        <div className='social_media_icon_container'>
                          {
                          siteConfig.facebook_link==""?(<a  href="#"> <FacebookIcon/></a> ):(<a target="_blank" href={siteConfig.facebook_link}> <FacebookIcon/></a> )
                          }
                        </div>
                        <div className='social_media_icon_container'>
                          { 
                            siteConfig.instagram_link==""?(<a  className='' href="#"><InstagramIcon/></a>):(<a target="_blank" className='' href={siteConfig.instagram_link}><InstagramIcon/></a>) 
                          }
                        </div>
                        <div className='social_media_icon_container'>
                            {
                                 siteConfig.Likedin_link==""?(<a  href="#"> <LinkedInIcon/></a>):(<a target="_blank" href={siteConfig.Likedin_link}> <LinkedInIcon/></a>)
                            }
                        </div>
                        <div className='social_media_icon_container'>
                            {
                                 siteConfig.telegram_link==""?(<a  href="#"> <Telegram/></a>):(<a target="_blank" href={siteConfig.telegram_link}> <Telegram/></a>)
                            }
                        </div>
                    </div>
                    </div>
                </div>

            </div>

        </div>
            <div className='col-sm-12 col-md-3 p-2'>
                <h6 className='footer_title'>Usefull Links</h6>
                
                <div className='links_container'>
                   <div><Link className="links" to="/"><span><KeyboardArrowRightIcon/></span> <h6>Home</h6></Link></div>
                   <div><Link className="links" to="/About"><span><KeyboardArrowRightIcon/></span> <h6>About us</h6></Link></div>
                   <div><Link className="links"to="/Blogs"><span><KeyboardArrowRightIcon/></span> <h6>Blog</h6></Link></div>
                   <div><Link className="links"to="/Members"><span><KeyboardArrowRightIcon/></span> <h6>Members</h6></Link></div>
                   <div><Link className="links" to="/Contactus"><span><KeyboardArrowRightIcon/></span> <h6>Contact us</h6></Link></div>
                </div>
            </div>
            <div className='col-sm-12 col-md-3 p-2'>
                <h6 className='footer_title'>Contact Info</h6>
                <div className='links_container'>
                <div><span><PlaceIcon/></span><h6>{siteConfig.Address==""? "Addis Ababa Bole":siteConfig.Address}</h6></div>
                <div><span><Phone/></span><h6> {siteConfig.primary_phonenumber==""? "+251929323458":siteConfig.primary_phonenumber} {siteConfig.secondary_phonenumber==""? "":` / ${siteConfig.primary_phonenumber}`}</h6></div>
                <div><span><EmailIcon/></span><h6> {siteConfig.primary_email==""? "EEVa @info.com":siteConfig.primary_email} {siteConfig.secondary_email==""? "":` / ${siteConfig.secondary_email}`}</h6></div>

                </div>
            </div>

        </div>
      
    </div>
    <div className='container-fluid  p-0 rounded'>
            <div className='col-sm-12 p-0'>
                <div className='siteownership_container'>
                    <h5 className=''><CopyrightIcon/>Copyright EEvA. All Rights Reserved</h5>
                    <h6>Designed by <a href='https://cubeaddis.com' target="_blank"><span>Cube Addis Solutions</span></a></h6>
                    </div>
            </div>
        </div>
    </>

  )
}

export default HomePage_footer