import React ,{useEffect, useState}from 'react';
import {  useNavigate } from 'react-router-dom';

import PostAddIcon from '@mui/icons-material/PostAdd';
import CloseIcon from '@mui/icons-material/Close';
import TitleHeader from '../components/TitleHeader';
import Alert_Modals from '../../components/Alert_Modals';
import { useSelector } from 'react-redux';
import { get_blog_images, remove_blog_images } from '../Functions/admin_functions';
import api from '../../Apis/api';
import {Editor} from "primereact/editor"
import { get_today_date } from '../../functions/member_functions';
function Edit_blog_page() {
    const [account,setAccount]=  useState((JSON.parse(localStorage.getItem("Admin_user_account")))||null);
    const selected_blog= useSelector((state)=>state.admin_reducer.selected_blog);
    const navigate= useNavigate();
    const [images,setImages]=useState();
    const [alert_dialog,setDialog]=useState(false)
    const[alert_text,setText]=useState("");
    const[alert_type,setType]=useState("");
    const [submitbutton,setStatus]=useState(false);
    const[blog_images,setBlogImages]=useState();
    const [blog_info,setInfo]=useState({
        blog_title:"",
        blog_content:"",
        short_description:"",
        blog_photos:null,
        blog_status:"",
        timestamp:get_today_date()
      });
    const fetch_data=()=>{
        get_blog_images(selected_blog.id).then((res)=>{
            if(res.status=="success")
            {
                setImages(res.images)

            }
            else
            {
                setDialog(true);
                setText("Error occured while trying to retrive Blog Images");
                setType("error")
            }
    });
    }
    const handel_textarea_change=(e)=>{
      setInfo( (prev)=>({
        ...prev,
        blog_content:e.htmlValue
      }))
    }
    const handel_short_desc_change=(e)=>{
      setInfo( (prev)=>({
        ...prev,
        short_description:e.htmlValue
      }))
    }
    
    const onHandelSubmit=async(e)=>{
        e.preventDefault();
        const formData= new FormData();
        for ( var key in blog_info ) {
            if(key=="blog_photos"&& blog_info.blog_photos!==null)
            {
             for (let i = 0; i < blog_info.blog_photos.length; i++) {
               formData.append(key, blog_info.blog_photos[i]);
             }
            }
             else{ 
               formData.append(key, blog_info[key]);}
          }
          try {
              const response=await api.put(`/blog/update/${selected_blog.id}`,formData,
              {
                headers:{"Content-Type":"multipart/form-data"}
              });
              if( response.data.status=="success" && response.data.result>=1)
              {
               setText("Blog Updated successfully")
               setType("success");
               setDialog(true);
               setTimeout(() => {
                   setDialog(false);
                   fetch_data();
               }, 1500);
              }
              

          } catch (error) {
            console.log(error);
            setText(error.response.data.error)
            setType("error");
            setDialog(true);
          }
    }
    const calculateTotalSize=(files)=> {
      var totalSize = 0;
     files.map((file) => totalSize += file.size)
        return totalSize;
    }
    const onHandelChange=(e)=>{
        setDialog(false);
        if(e.target.name=="blog_photos")
        {
          const files=e.target.files;
   
          if(calculateTotalSize(Array.from(files))<=3145728) 
            {
            setStatus(false);
          setInfo( (prev)=>({
           ...prev,
           [e.target.name]:files
         }))
         setBlogImages(Array.from(files));
        }
        else{
          setText("File size to large")
          setType("error");
          setDialog(true);
          setStatus(true)
        }
        }
        else
        {
         setInfo( (prev)=>({
           ...prev,
           [e.target.name]:e.target.value
         }))
        }
       }
    useEffect(()=>{
        if (account==null)
        {
            navigate("/admin/login");
        }
        else{

            if(selected_blog==null)
            {
                navigate("/Admin/Blog-Management")
            }
            else
            {
                setInfo((pre)=>({
                    ...pre,
                    blog_title:selected_blog.title,
                    blog_content:selected_blog.content,
                    blog_status:selected_blog.status,
                    short_description:selected_blog.short_desc
                    
                }))
                fetch_data();
            
            
            }
        }

    },[selected_blog])
   const Hande_Image_remove=(img_id,image_path)=>{
    if(window.confirm("Are you sure you want to remove this Image"))
    {
       remove_blog_images(img_id,image_path).then((res)=>{
        if(res.status=="success")
        {
            setDialog(true);
            setType("success")
            setText("Blog Image has been successfuly removed")
            setTimeout(() => {
                setDialog(false);
                fetch_data()
              }, 1500);
              
              
        }
        else
        {
            setDialog(true);
            setType("error");
            setText("Internal Server Error occured while trying to remove the Image")
            setTimeout(() => {
             setDialog(false);
           }, 1500);
        }
    })
    }
   }
  return (
    <div className='container-fluid'>
    <div className='container-fluid whole_title_container shadow '>
    <TitleHeader icon={<PostAddIcon/>} title="Edit Blog"/>
    </div>
    <div className='container-fluid rounded shadow admin_color mt-5 p-3'>
        <div className='row'>
        <div className='col-sm-12 col-lg-4 '>
        <div id="demo" className="carousel slide" data-bs-ride="carousel">

{/* <!-- Indicators/dots --> */}
<div className="carousel-indicators">
 {
    blog_images && blog_images.map((image,index)=>{
      return(
        <button type="button" data-bs-target="#demo" data-bs-slide-to={index} className={index==0?"active bg-success":"bg-success"}></button>
      )
    })
 } 
</div>

{/* <!-- The slideshow/carousel --> */}
<div className="carousel-inner">
  {
    blog_images && blog_images.map((image)=>{
      return(
        <div className="carousel-item active">
    <img src={URL.createObjectURL(image)} alt={image.name} className="d-block rounded w-100" />
  </div>
      )
    })
  }
  
  
</div>

{/* <!-- Left and right controls/icons --> */}
<button className="carousel-control-prev " type="button" data-bs-target="#demo" data-bs-slide="prev">
  <span className="carousel-control-prev-icon bg-success p-2 rounded "></span>
</button>
<button className="carousel-control-next" type="button" data-bs-target="#demo" data-bs-slide="next">
  <span className="carousel-control-next-icon bg-success p-2 rounded"></span>
</button>
</div>
            </div>
            <div className='col-sm-12 col-lg-4 '>
            <form onSubmit={onHandelSubmit}>
                    {alert_dialog && <Alert_Modals type={alert_type} text={alert_text}/>}
                    <div className="form-floating mb-3 mt-3">
                      <input type="Text" className="form-control" value={blog_info.blog_title} onChange={onHandelChange} required id="Title" placeholder="Enter Title" name="blog_title"/>
                      <label htmlFor="Text">Blog Title</label>
                     </div>
                     <div className=" mb-3 mt-3">
                     {/* <textarea className="form-control" rows="8" id="blog_content" value={blog_info.blog_content} required onChange={onHandelChange} name="blog_content"></textarea> */}
                     <label htmlFor="Text">Short Description</label>
                     
                     <Editor value={blog_info.short_description} className='bg-white rounded' name='content' style={{height:"200px"}} onTextChange={handel_short_desc_change}/>
                     {/* <label htmlFor="Text">Blog Title</label> */}
                     </div>
                     <div className=" mb-3 mt-3 bg">
                     <label htmlFor="Text">Full Content</label>
                     <Editor value={blog_info.blog_content} className='bg-white rounded' name='content' style={{height:"400px"}} onTextChange={handel_textarea_change}/>
                      {/* <label htmlFor="Text">Blog Title</label> */}
                     </div>
                     <div className="form-floating mb-3 mt-3">
                    <input type="file" className="form-control" multiple accept=".jpg, .jpeg, .png" onChange={onHandelChange}  id="profile_pic" placeholder="Upload profile" name="blog_photos"/>
                    <label for="profile_pic">profile pic</label>
                      </div>
                      <div className="form-floating mb-3 mt-3">
      <select className="form-control" onChange={onHandelChange} required value={blog_info.blog_status} name='blog_status'>
                <option value="">---please select Status---</option>
                <option value="Active">Active</option>
                <option value="inactive">inactive</option>
             
            </select>
            <label htmlFor="title">Status</label>
      </div>
                     <div className='row p-3'>
                        <button type='submit'   className='btn btn-success fw-bold' >
                       Update Post <PostAddIcon/>
                        </button>
                      </div>
                    </form>
            </div>
            <div className='col-sm-12 col-lg-4 border-start  border-warning'>
                <div className="row">
                 <h5 className='text-dark fw-bold mt-3'> Current Images</h5>
                        {
                            images && images.map((image)=>
                            {
                                return(
                                    <div className=' col-sm-12 col-lg-4 p-2'>
                                       <div className='d-flex flex-column bg-light shadow p-2 justify-content-end rounded'>
                                           <CloseIcon onClick={()=>Hande_Image_remove(image.img_id,image.image_path)}/>
                                       <img src={`https://dev-eeva-server.ethiopianevaluationassociation.org/Blogs/${image.image_path}`} alt={image.img_id} className='img-fluid rounded'/>
                                           </div>
                                       
                                    </div>
                                )
                            })
                        }
                    
                
            </div>
        </div>
        </div>
    </div>
    </div>
  )
}

export default Edit_blog_page