import React, { useEffect, useState } from 'react'
import "../Css_for_components/edit_profile_page.css"
import {  useNavigate,Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { convert_to_date, get_today_date, validateEthiopianPhoneNumber } from '../functions/member_functions';
import { check_id_status, get_a_member_transaction, get_member_account } from '../functions/api_call_functions';
import Alert_Modals from '../components/Alert_Modals';
import api from '../Apis/api';
import BadgeIcon from '@mui/icons-material/Badge';
import { set_member } from '../Store/Action';
import Payment_Modal from '../components/Payment_Modal';
import { ContactlessOutlined, Home, LinkedIn } from '@mui/icons-material';
import { Editor } from 'primereact/editor';

function Edit_profile_page() {
 
  const [charCount, setCharCount] = useState(0);
  const maxLength = 500;  // Maximum number of characters allowed
    const [user_info,setInfo]=useState({
        account_id:null,
        firstname:"",
        lastname:"",
        gender:null,
        title:null,
        email:"",
        profile_pic:null,
        username:"",
        dob:"",
        bio:"",
        address:"",
        experience:"",
        education_level:"",
        profession:"",
        Linkedin_link:"",
        phonenumber:"",
        role:"Member",
        status:"Active",
        Representation:"",
        Institution:null,
        end_date:get_today_date(),
        created_date:get_today_date()
  
    })
    const navigate=useNavigate();
    const dispatch= useDispatch();
    const [idStatus,setStatus]=useState(false)
    const [alert_dialog,setDialog]=useState(false)
    const[alert_text,setText]=useState("");
    const[alert_type,setType]=useState();
    const [payment_Modal,set_Payment_Modal]=useState(null); 
    const Payment_Modal_toggle=()=>{
      set_Payment_Modal(!payment_Modal);
    }
  
  // for handling submit
  const handel_submit=async(e)=>{
    e.preventDefault();
    const value=validateEthiopianPhoneNumber(user_info.phonenumber);
   if (value.isValid)
    {
    // console.log(user_info.profile_pic)
    const formData= new FormData();
    for ( var key in user_info ) {
        formData.append(key, user_info[key]);
    }
    try {
        const response=await api.put("/member/profile/update",formData,
              {
            headers:{"Content-Type":"multipart/form-data", }
        })
        // console.log(response);
        if(response.data.status=="success")
        {
          setDialog(true);
          setText("Profile Updated successfully")
          setType("success");
        
          setTimeout(() => {
            setDialog(false);
          }, 3000);
         
          get_member_account(user_info.account_id,JSON.parse(localStorage.getItem("Token"))).then((res)=>{
            if(res.status==="success")
            {
            dispatch(set_member(res.user));
            localStorage.setItem("Member_account",JSON.stringify(res.user))
            
            }
            else{
              setText("Error occured while try to fetch you account please try loging in from login page ")
              setType("error");
              setDialog(true);
            }
          }).catch(err => {
            // Update the state with the error
            console.log(err)
        });
        }
        
    } catch (error) {
        // console.log("here is the error",error);
        setText("there was an internal server Error while updating your Profile")
        setType("error");
        setDialog(true);
     
      
    }}
    else{
      setDialog(true);
            setText(value.message)
            setType("error")
            setTimeout(() => {
                setDialog(false);
              }, 4000);
    }
}
   //for handiling change in the input fileds
   const handel_change=(e)=>{
    if(e.target.name=="profile_pic")
    {  const file=e.target.files[0];
        
        setInfo((pre)=>
        ( 
          {...pre,
            image_profile:null,
          [e.target.name]:file
          }
        )
      )
    }
    else
    {
        setInfo((pre)=>
    ( 
      {...pre,
      [e.target.name]:e.target.value
      }
    )
  )
    }
    
};
const handel_textarea_change=(e)=>{

  const newContent = e.htmlValue;
  const textLength = newContent && newContent.replace(/<[^>]+>/g, '').length; 
  if (textLength <= maxLength) {
      console.log("ture")
    setInfo((pre)=>
      ( 
        {...pre,
        bio:e.htmlValue
        }
      )
    )
    setCharCount(textLength);  // Update character count
  }
 
}

//set storge


const member=JSON.parse(localStorage.getItem("Member_account"));
console.log(member);
// const member= useSelector((state)=>state.member_reducer.member)
    useEffect(()=>{
       setInfo({
        account_id:member.account_id,
        firstname:member.firstname,
        lastname:member.lastname,
        gender:member.gender,
        title: member.title,
        email:member.email,
        username:member.username,
        img_location:member.profile_image,
        phonenumber:member.phonenumber,
        role:member.role,
        image_profile:member.profile_image,
        dob:convert_to_date(member.dob),
        bio:member.bio,
        address:member.address,
        experience:member.experience,
        education_level:member.education_level,
        profession:member.profession,
        Linkedin_link:member.Linkedin_link,
        profile_pic:null,
        status:member.membership_status,
        end_date:convert_to_date(member.membership_enddate),
        created_date:get_today_date(),
        Representation:member.Representation,
        Institution:member.Institution,
       })
      
  
     check_id_status(member.account_id).then((res)=>{
        if (res.status=="success"){
            if(res.result==1)
            {setStatus(true);}
        }
       }) .catch(err => {
        // Update the state with the error
        console.log(err)
    });
        
    },[])
  return (
    <div className='container-fluid p-2'>
        <div className='container'>
         <div className='d-flex justify-content-around mt-3'>
          
         <Link className='btn btn-success' to="/"><Home/></Link>
        { idStatus? (<span className=' p-2 bg-success text-white rounded'>Your Digital Id is under development </span>):(<button className='btn btn-primary fw-bold ' onClick={Payment_Modal_toggle}>Request Digital ID <BadgeIcon/></button>)}
         </div>
          
            <div className='row'>
                <div className='col-sm-12 col-lg-5  p-3'>
                    <div className='profile_update_container rounded shadow'>
                        <div className='profile_image_container'>
                            <img src={user_info.image_profile?`https://eeva-server.ethiopianevaluationassociation.org/Profile/${user_info.image_profile}`:user_info.profile_pic? URL.createObjectURL(user_info.profile_pic):"/Assets/blank-profile-picture.webp"} className='img-fluid rounded-circle' alt='profile_pic'/>
                            
                        </div>
                        <div className='profile_info_container d-none d-lg-block'>
                             <div className='container ' >
                             <div class="row">
                             <div class="col-sm-12" className='text-justify' dangerouslySetInnerHTML={{__html:user_info.bio}}>
                               
                                </div>
                                </div>
                            </div>
                            <div>
                                <h6> First Name :<span>{user_info.firstname}</span></h6>
                            </div>
                            <div>
                                <h6> Last Name :<span>{user_info.lastname}</span></h6>
                            </div>
                            <div>
                                <h6> Gender :<span>{user_info.gender}</span></h6>
                            </div>
                            <div>
                                <h6> Title :<span>{user_info.title}</span></h6>
                            </div>
                            <div>
                                <h6> Date of Birth :<span>{user_info.dob}</span></h6>
                            </div>
                            <div>
                                <h6> Address :<span>{user_info.address}</span></h6>
                            </div>
                            <div>
                                <h6> Email :<span>{user_info.email}</span></h6>
                            </div>
                            <div>
                                <h6> Phone :<span>{user_info.phonenumber}</span></h6>
                            </div>
                            <div>
                                <h6> Education level :<span>{user_info.education_level}</span></h6>
                            </div>
                            <div>
                                <h6> Profession :<span>{user_info.profession}</span></h6>
                            </div>
                            <div>
                                <h6> Experience :<span>{user_info.experience}</span></h6>
                            </div>
                            <div>
                                <h6> <LinkedIn/> :<span>{user_info.Linkedin_link}</span></h6>
                            </div>
                            <div>
                                <h6> Membership end date :<span>{user_info.  end_date}</span></h6>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-sm-12 col-lg-7 p-3'>
                <div className='bg-light rounded shadow p-3'>
                  <div className='d-flex justify-content-center'>
                  <img src='../Assets/EEVA_logo.png' alt='Logo' className=' login_page_logo img-fluid rounded'/>
                  </div>
                <h3 className=' register_title'>Update your Profile</h3>
                    <form onSubmit={handel_submit} >
                    {alert_dialog && <Alert_Modals type={alert_type} text={alert_text}/>}
                    <div className="row">
    <div className="col-sm-12 col-lg-6">
            <div className="form-floating mb-3 mt-3">
        <input type="text" className="form-control" onChange={handel_change} required value={user_info.firstname}id="firstname" placeholder="Enter first name" name="firstname"/>
        <label for="firstname">First Name</label>
        </div>    
    </div>
    <div className="col-sm-12 col-lg-6">
    <div className="form-floating mb-3 mt-3">
  <input type="text" className="form-control" onChange={handel_change} required value={user_info.lastname}id="lastname" placeholder="enter last name" name="lastname"/>
  <label for="lastname">Last Name</label>
</div>
    </div>
  </div>
             
  <div className="row">
      <div className="col-sm-12 col-lg-6">
      <div className="form-floating mb-3 mt-3">
          <select className="form-control" onChange={handel_change} required value={user_info.gender}name='gender'>
             <option value="">please select Gender</option>
              <option value="Male">Male</option>
              <option value="female">Female</option>
          </select>
          <label for="Gender">Gender</label>
        </div>    
      </div>
    <div className="col-sm-12 col-lg-6">
      <div className="form-floating mb-3 mt-3">
      <select className="form-control" onChange={handel_change} required value={user_info.title} name='title'>
                <option value="">---please select Title---</option>
                <option value="Mr">Mr</option>
                <option value="Mrs">Mrs</option>
                <option value="Mrs">Mrs</option>
                <option value="Miss">Miss</option>
                <option value="Dr">Dr</option>
                <option value="Prof">Prof</option>
            </select>
            <label for="title">Title</label>
      </div>
    </div>
  </div>
      <div className="form-floating mb-3 mt-3">
        <input type="username" disabled="true" className="form-control" value={user_info.username}onChange={handel_change} required id="username" placeholder="Enter username" name="username"/>
        <label for="Username">Username</label>
    </div>

    <div className="row">
        <div className="col-sm-12 col-lg-6">
        <div className="form-floating mt-3 mb-3">
      <input type="date" className="form-control"  value={user_info.dob} onChange={handel_change} required id="email" placeholder="Enter Dob" name="dob"/>
      <label for="dob">Date of Birth</label>
    </div>
        </div>
        <div className="col-sm-12 col-lg-6">
        <div className="form-floating mt-3 mb-3">
          <input type="text" className="form-control" value={user_info.address} onChange={handel_change} required id="phonenumber" placeholder="Enter Address" name="address"/>
      <label for="address">Address</label>
    </div>
    </div>
</div>

      <div className="row">
        <div className="col-sm-12 col-lg-6">
        <div className="form-floating mt-3 mb-3">
      <input type="email" className="form-control"  value={user_info.email} onChange={handel_change} required id="email" placeholder="Enter email" name="email"/>
      <label for="email">Email</label>
    </div>
        </div>
        <div className="col-sm-12 col-lg-6">
        <div className='input-group mt-3 mb-3'>
          <span class="input-group-text fw-bold">+251</span>
        <div className="form-floating  ">
                  <input type="text" className="form-control" value={user_info.phonenumber} onChange={handel_change} required id="phonenumber" placeholder="Enter phonenumber" name="phonenumber"/>
      <label for="phonenumber">Phonenumber</label>
    </div>
    </div>
    </div>
</div>
<div className="row">
        <div className="col-sm-12 col-lg-6">
        <div className="form-floating mb-3 mt-3">
      <select className="form-control" onChange={handel_change} required value={user_info.education_level} name='education_level'>
                <option value="">---please select Education Level---</option>
                <option value="Bachelor's degree">Bachelor's degree</option>
                <option value="Master's Degree">Master's Degree</option>
                <option value="Doctoral degree">Doctoral degree</option>
                <option value="Certificate / Diploma">Certificate / Diploma</option>
            </select>
            <label for="title">Education Level</label>
      </div>
        </div>
        <div className="col-sm-12 col-lg-6">
        <div className="form-floating mt-3 mb-3">
          <input type="text" className="form-control" value={user_info.profession} onChange={handel_change} required id="profession" placeholder="Enter profession" name="profession"/>
      <label for="profession">Profession</label>
    </div>
    </div>
</div>

<div className="row">
        <div className="col-sm-12 col-lg-6">
        <div className="form-floating mb-3 mt-3">
        <select className="form-control" onChange={handel_change} required value={user_info.experience} name='experience'>
                <option value="">---please years of experience---</option>
                <option value="1-2">1-2</option>
                <option value="3-5">3-5</option>
                <option value="5-7">5-7</option>
                <option value="7-10">7-10</option>
                <option value="10+">10+</option>

            </select>
            <label for="title">Years of Experience</label>
            </div>
        </div>
        <div className="col-sm-12 col-lg-6">
        <div className="form-floating mt-3 mb-3">
          <input type="text" className="form-control" value={user_info.Linkedin_link} onChange={handel_change}  id="Linkedin_link" placeholder="Enter Linkedin_link" name="Linkedin_link"/>
      <label for="phonenumber">LinkedIn Link</label>
    </div>
    </div>
</div>

<div className="mb-3 mt-3">
<label for="bio">Bio</label>
        <Editor value={user_info.bio} onTextChange={handel_textarea_change} name='bio' id='bio' style={{height:"200px"}} max={100}/>
        <div className="mt-3">
        <p>Character Count: {charCount}/{maxLength}</p>  {/* Display character count */}
      </div>
        
    </div>

    <div className='row'>
<div className={ user_info.Representation=="Institution"?"col-sm-12 col-lg-6 ":"col-sm-12 col-lg-12 "}>
<div className="form-floating mb-3 mt-3">
      <select className="form-control" onChange={handel_change} disabled required value={user_info.Representation} name='Representation'>
                <option value="">---please select Representation---</option>
                <option value="Individual">Individual</option>
                <option value="Institution">Institution</option>
            </select>
            <label for="title">Representation</label>
      </div>
      </div>
    { user_info.Representation=="Institution"?
      (<div className="col-sm-12 col-lg-6">
<div className="form-floating mb-3 mt-3">
<input type="text" className="form-control" onChange={handel_change} required={user_info.Representation=="Institution"? "true" :"false"} value={user_info.Institution}id="Institution"  name="Institution"/>
<label for="title">Institution</label>
      </div>
      </div>):<></>}

</div>


<div className="form-floating mb-3 mt-3">
        <input type="file" className="form-control" accept=".jpg, .jpeg, .png" onChange={handel_change}  id="profile_pic" placeholder="Upload profile" name="profile_pic"/>
        <label for="profile_pic">profile pic</label>
    </div>
    <div className="d-flex justify-content-center mt-3 mb-3">
      <button type='submit' className='btn btn-success w-100 fw-bolder mx-auto'>Update</button>
     </div>
     
 </form>
                </div>
            </div>
            </div>
        </div>
        <Payment_Modal modal_status={payment_Modal} Modal_toggle={Payment_Modal_toggle} account={member} payment_reason_data={{reason:"ID",amount:300}} />

    </div>
  )
}

export default Edit_profile_page