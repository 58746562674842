import React, { useState ,useEffect} from 'react'
import GroupIcon from '@mui/icons-material/Group';
import TitleHeader from '../components/TitleHeader';
import "../css/general.css";
import ImportExportIcon from '@mui/icons-material/ImportExport';
import RefreshIcon from '@mui/icons-material/Refresh';
import { get_all_members } from '../Functions/admin_functions';
import SimpleBackdrop from"../components/SimpleBackdrop";
import * as XLSX from 'xlsx'
import moment from 'moment';
import { get_today_date } from '../../functions/member_functions';
import Member_ViewTable from '../components/Member_ViewTable';
import { useNavigate } from 'react-router-dom';
import Alert_Modals from '../../components/Alert_Modals';
import { get_member_filter_date } from '../Functions/filter_functions';
function Member_managment() {
    const[allmembers,setMembers]=useState(null);
    const [datefilters,setFilters]=useState({
      start_date:null,
      end_date:null
  }); 
    const [account, setAccount]= useState((JSON.parse(localStorage.getItem("Admin_user_account")))||null);
    const navigate= useNavigate();
    const [alert_dialog,setDialog]=useState(false);
    const[alert_text,setText]=useState("");
    const[alert_type,setType]=useState();
    const get_members=()=>
    {
        get_all_members().then((res)=>{
            // console.log(res);
            setMembers(res)
        })
    }
    const refresh_data=()=>
    {
        setMembers(null);
        get_members()
        setFilters({
          start_date:"",
          end_date:""
      })
    }
     //use Effect for loading existing members
     useEffect(()=>{
      if (account==null)
        {
            navigate("/admin/login");
        }
        else{
          get_members();
        }
      
       
    },[])
    const handel_change=(e)=>{
      setFilters( (prev)=>({
        ...prev,
        [e.target.name]:e.target.value
      }))
    }
    const onhandelSubmit=(e)=>{
      e.preventDefault();
      if(datefilters.start_date !==null)
      { setMembers(null);
        get_member_filter_date(datefilters.start_date,datefilters.end_date).then((res)=>{
        if(res.status=="success")
        {
            setMembers(res.members);
        }
        else{
          setText("Internal server Error occured while try to fetch Data please try again ")
          setType("error");
          setDialog(true);
          setTimeout(() => {
            setDialog(false);
          }, 3000);
        }
      })}
      else{
       
      }
    }
    //handel export
    const handlonExport=()=>
      {
          var wb= XLSX.utils.book_new();
         var ws=XLSX.utils.json_to_sheet(allmembers);
         XLSX.utils.book_append_sheet(wb,ws,"Member");
         XLSX.writeFile(wb,"MyExcel.xlsx")

      }
 
  return (
    <div className='container-fluid '>
       
        <div className='container-fluid whole_title_container shadow '>
      <TitleHeader icon={<GroupIcon/>} title="Member Management"/>
      </div>
      <div className='container-fluid admin_container_color'>
          <div className='row p-2'> 
              <div className='col-sm-12 col-lg-8 my-2 px-lg-5 '>
              {alert_dialog && <Alert_Modals type={alert_type} text={alert_text}/>}

              <form onSubmit={onhandelSubmit} className='border-end'>
                    <div className='row'>
                      <div className='col-sm-12 col-lg-4 mx-auto'>
                          <div class="mb-3 px-3 ">
                        <label for="Start_date" class="form-label text-dark">Start Date:</label>
                        <input type="Date" onChange={handel_change} required value={datefilters.start_date} max={moment(get_today_date() ).format('YYYY-MM-DD')} className="form-control" id="start_date"  name="start_date"/>
                        </div>
                      </div>
                      <div className='col-sm-12 col-lg-4 mx-auto'>
                      <div class="mb-3 px-3  ">
                    <label for="end_date" class="form-label text-dark">End Date:</label>
                    <input type="Date" onChange={handel_change} value={datefilters.end_date} className="form-control" id="end_date " max={moment(get_today_date() ).format('YYYY-MM-DD')}  name="end_date"/>
                    </div>
                      </div>
                      <div className='col-sm-12 col-lg-2 mx-auto'>
                      <div className='my-lg-4 py-lg-2 w-100'>
                        <button type='submit' className='btn btn-success w-100'>Search</button>
                    </div>
                      </div>
                    </div>
                  
                  
                    
                    </form>

              </div>
              <div className='col-sm-12 my-2 col-lg-4 px-lg-5'>
                <div className='d-flex justify-content-around'>
                <button className='btn btn-primary' onClick={handlonExport} > <ImportExportIcon/> Export</button>
                    <button className='btn btn-info fw-bold mx-2' onClick={refresh_data} >Refresh <RefreshIcon/></button>
                </div>

               </div>
          </div>
          <div className='row mt-3'>
        <div className='col-sm-12 p-2'>
          {allmembers ? <Member_ViewTable result={allmembers} refresh_data={refresh_data} className="text-dark"/>:<SimpleBackdrop/>}
        </div>

      </div>

      </div>
            
        </div>

  
  )
}

export default Member_managment