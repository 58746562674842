import React,{useState,useEffect} from 'react';
import {Editor} from "primereact/editor"
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import TitleHeader from '../components/TitleHeader';
import Alert_Modals from '../../components/Alert_Modals';
import { check_site_config } from '../Functions/admin_functions';
import api from '../../Apis/api';
function Configuration_managment() {
  const [alert_dialog,setDialog]=useState(false)
  const[alert_text,setText]=useState("");
  const[alert_type,setType]=useState();
  const [config,setConfig]=useState();
  const[siteInfo,setInfo]=useState({
 
    primary_email:"",
    secondary_email:"",
    primary_phonenumber:"",
    secondary_phonenumber:"",
    Address:"",
    Description:"",
    twitter_link:"",
    facebook_link:"",
    telegram_link:"",
    instagram_link:"",
    Likedin_link:"",
    id:""

  })
  useEffect(()=>{
       check_site_config().then((res)=>{
         if(res.result>0){
           const info= res.configuration[0];
           setInfo({
            primary_email:info.primary_email,
            secondary_email:info.secondary_email,
            primary_phonenumber:info.primary_phonenumber,
            secondary_phonenumber:info.secondary_phonenumber,
            Address:info.Address,
            Description:info.Description,
            twitter_link:info.twitter_link,
            facebook_link:info.facebook_link,
            telegram_link:info.telegram_link,
            instagram_link:info.instagram_link,
            Likedin_link:info.Likedin_link,
            id:info.Id,
           })
         }
      
       }) 
  },[])
  const onHandelChange=(e)=>{
    setInfo( (prev)=>({
      ...prev,
      [e.target.name]:e.target.value
    }))
  }
  const handel_textarea_change=(e)=>{
    setInfo( (prev)=>({
      ...prev,
      Description:e.htmlValue
    }))
  }
  const onHandelSubmit=async(e)=>{
    e.preventDefault();
    // console.log(siteInfo);
    try {

      check_site_config().then((res)=>{
       setConfig(res);
      });
      
      if(config.result<=0){
        const response= await api.post("/configuration/Upload/",{
          siteInfo
        });
        if(response.data.status=="success")
        {
          setText("Configuration Uploaded successfully")
          setType("success");
          setDialog(true);
          setTimeout(() => {
            setDialog(false);
          }, 1500);
        }
      }
      else{
        const response= await api.put("/configuration/update/",{
          siteInfo
        });
        // console.log(response);
        if(response.data.result==1)
        {
          setText("Configuration Updated successfully")
          setType("success");
          setDialog(true);
          setTimeout(() => {
            setDialog(false);
          }, 1500);
        }
        else{
          setText("Internal server error while updating Configuration")
          setType("error");
          setDialog(true);
          setTimeout(() => {
            setDialog(false);
          }, 1500);
        }
      }
      
    } catch (error) {
      console.log(error)
      setText("Internal server error while uploading Configuration")
      setType("error");
      setDialog(true);
      setTimeout(() => {
        setDialog(false);
      }, 1500);
      
    }
    
  }


  return (
    <div className='container-fluid'>
         <div className='container-fluid whole_title_container shadow '>
    <TitleHeader icon={<SettingsSuggestIcon/>} title="Site Configuration"/>
    </div>
    <div className='container-fluid mt-4 rounded admin_color p-3 p-lg-5 '>
      <div className='row'>
        <div className='col-sm-12 col-lg-9 bg-white mx-auto rounded shadow p-3'>
        {alert_dialog && <Alert_Modals type={alert_type} text={alert_text}/>}

          <h6 className='text-dark fw-bold'> Fill in this form to make configuration on the site</h6>
          <form className=''onSubmit={onHandelSubmit}>
                            <div className="row">
                          <div className="col-sm-12 col-lg-6">
                          <div className="form-floating mt-3 mb-3">
                        <input type="email" className="form-control"  value={siteInfo.primary_email} onChange={onHandelChange} required id="email" placeholder="Enter email" name="primary_email"/>
                        <label htmlFor="email">Primary Email</label>
                      </div>
                          </div>
                          <div className="col-sm-12 col-lg-6">
                          <div className="form-floating mt-3 mb-3">
                          <input type="email" className="form-control"  value={siteInfo.secondary_email} onChange={onHandelChange} id="email" placeholder="Enter email" name="secondary_email"/>
                          <label htmlFor="email">Secondary Email</label>
                      </div>
                      </div>
                  </div>
                  <div className="row">
                          <div className="col-sm-12 col-lg-6">
                          <div className="form-floating mt-3 mb-3">
                          <input type="text" className="form-control" value={siteInfo.primary_phonenumber} onChange={onHandelChange} required id="phonenumber" placeholder="Enter phonenumber" name="primary_phonenumber"/>
                        <label htmlFor="phonenumber"> primary Phonenumber</label>

                      </div>
                          </div>
                          <div className="col-sm-12 col-lg-6">
                          <div className="form-floating mt-3 mb-3">
                          <input type="text" className="form-control"  value={siteInfo.secondary_phonenumber} onChange={onHandelChange} id="email" placeholder="Enter email" name="secondary_phonenumber"/>
                          <label htmlFor="phonenumber">Secondary Phonenumber</label>         
                        
                      </div>
                      </div>
                  </div>
                  <div className="row">
                          <div className="col-sm-12">
                          <div className="form-floating mt-3 mb-3">
                          <input type="text" className="form-control" value={siteInfo.Address} onChange={onHandelChange} required id="phonenumber" placeholder="Enter phonenumber" name="Address"/>
                        <label htmlFor="phonenumber"> Address</label>
                      </div>
                          </div>
                  </div>
                  <div className='row'>
                    <div className='col-sm-12 col-lg-12  mx-auto'>
                    <label htmlFor="phonenumber" className='mt-3 fw-bold'>Short-description</label>
                    <Editor required value={siteInfo.Description} name='content' style={{height:"300px"}} onTextChange={handel_textarea_change}/>

                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-sm-12 col-lg-12  mx-auto'>
                    <label htmlFor="phonenumber" className='mt-3 fw-bold'>Social Medial Links</label>
                      <div className='row'>
                      <div className='col-sm-12 col-lg-6'>
                    <div className="form-floating mt-3 mb-3">
                          <input type="text" className="form-control" value={siteInfo.Likedin_link} onChange={onHandelChange}  id="phonenumber" placeholder="Enter phonenumber" name="Likedin_link"/>
                        <label htmlFor="phonenumber"> LinkedIn</label>

                    </div>
                    </div>
                    <div className='col-sm-12 col-lg-6'>
                    <div className="form-floating mt-3 mb-3">
                          <input type="text" className="form-control" value={siteInfo.telegram_link} onChange={onHandelChange}  id="phonenumber" placeholder="Enter phonenumber" name="telegram_link"/>
                        <label htmlFor="phonenumber"> Telegram</label>

                    </div>
                    </div>
                      </div>
                      <div className='row'>
                      <div className='col-sm-12 col-lg-6'>
                    <div className="form-floating mt-3 mb-3">
                          <input type="text" className="form-control" value={siteInfo.facebook_link} onChange={onHandelChange} id="phonenumber" placeholder="Enter phonenumber" name="facebook_link"/>
                        <label htmlFor="phonenumber"> FaceBook</label>

                    </div>
                    </div>
                    <div className='col-sm-12 col-lg-6'>
                    <div className="form-floating mt-3 mb-3">
                          <input type="text" className="form-control" value={siteInfo.instagram_link} onChange={onHandelChange}  id="phonenumber" placeholder="Enter phonenumber" name="instagram_link"/>
                        <label htmlFor="phonenumber">Instagram  </label>

                    </div>
                    </div>
                      </div>
                    
                    </div>
                  </div>
                  <div className='row p-3'>
                        <button type='submit'   className='btn btn-success fw-bold' >
                       Submit
                        </button>
                      </div>

          </form>

        </div>

      </div>


     

    </div>
    </div>
  )
}

export default Configuration_managment