import React, { useEffect, useState } from 'react'
import PersonIcon from '@mui/icons-material/Person';
import { Link } from 'react-router-dom';
import AccessTimeSharpIcon from '@mui/icons-material/AccessTimeSharp';
import { get_blog_images } from '../Admin/Functions/admin_functions';
import moment from 'moment';
import { CircularProgress } from '@mui/material';

function Blog({blog}) {
  const [images,setImages]=useState();
  const [loadingImages, setLoadingImages] = useState(true);
  useEffect(()=>{
    
    get_blog_images(blog.id).then((res)=>{
      if(res.status=="success")
      {
        setImages(res.images);
        setLoadingImages(false)
      }
    }
  )
  },[])

  if(  blog!== undefined && blog !== null) {
  return (
  
    loadingImages ? (
      <div className="loading-container">
          <CircularProgress className="large-spinner" color="inherit" />
      </div>
  ):( <div className='single_blog_container shadow rounded mt-3'>
            <div id="demo" className="carousel slide" data-bs-ride="carousel">

{/* <!-- Indicators/dots --> */}
<div className="carousel-indicators">
{
images && images.map((image,index)=>{
return(
<button type="button" key={index} data-bs-target="#demo" data-bs-slide-to={index} className={index==0?"active bg-success":"bg-success"}></button>
)
})
} 
</div>

{/* <!-- The slideshow/carousel --> */}
<div className="carousel-inner">
{
images && images.map((image,index)=>{
  
return(
<div className={index==0?"carousel-item active":"carousel-item "} key={image.img_id}>
<img src={`https://dev-eeva-server.ethiopianevaluationassociation.org/Blogs/${image.image_path}`} alt={image.img_id} className='d-block w-100 h-75 img-fluid rounded' style={{maxHeight:"600px"}}/>  </div>
)
})
}


</div>

{/* <!-- Left and right controls/icons --> */}
<button className="carousel-control-prev " type="button" data-bs-target="#demo" data-bs-slide="prev">
<span className="carousel-control-prev-icon bg-success p-2 rounded "></span>
</button>
<button className="carousel-control-next" type="button" data-bs-target="#demo" data-bs-slide="next">
<span className="carousel-control-next-icon bg-success p-2 rounded"></span>
</button>
</div>
        
        <h4>
        {blog.title}
        </h4>
        <div className='blog_info_container'>
            <div className='blog_details'>
                <div>
                <PersonIcon className="blog_details_icons"/>
                <h6>{blog.username}</h6>
                </div>
                <div>
                <AccessTimeSharpIcon className="blog_details_icons"/>
                <h6>
                {moment(blog.created_at ).format('YYYY-MM-DD')}
                </h6>
                </div>
            </div>
            <div className='blog_details_content text-secondary'  dangerouslySetInnerHTML={{__html:blog.short_desc}}>
          
              
            </div>
            <div className='d-flex w-100 justify-content-end my-3'>
              <Link to={`/Blogs/${blog.title}/${blog.id}`}><button className='btn btn-warning 'style={{fontWeight:"500"}}>Read More</button></Link>
            </div>
            
        </div>
    </div>)
  )}
}
export default Blog