import moment from "moment";
import api from "../Apis/api";
// to get todays date
export const get_today_date =()=>{
    var currentdate = new Date(); 
    var datetime =currentdate.getFullYear() + "-"
    + (currentdate.getMonth()+1)  + "-" 
    + currentdate.getDate();
      return datetime;
   
  }
  //convert to proper date format
  export const convert_to_date=(date1)=>{
    var currentdate =new Date (date1)
    const format="YYYY-MM-DD";
    const local="en-us";
    const date= moment(currentdate).format(format);
      return date;
   
  }

  //compare password
export const compare_password=(pass1,pass2)=>
{
  if(pass1==pass2)
  {
    return true
  }
  else{
      return false
  }
}
var currentdate = new Date(); 
export var today = currentdate.getFullYear() + "-"
+ (currentdate.getMonth()+1)  + "-" 
+ currentdate.getDate();

export const subDays=(end_date)=>{
  let date1 = new Date(today);
  let date2 = new Date(end_date);
  let Difference_In_Time =
  date2.getTime() - date1.getTime();

  let Difference_In_Days =
  Math.round
      (Difference_In_Time / (1000 * 3600 * 24));
     
      return Difference_In_Days;
      
}
//  for checking password strength
export function checkPasswordStrength(password) {
  const minLength = 8;
  const hasUpperCase = /[A-Z]/.test(password);
  const hasLowerCase = /[a-z]/.test(password);
  const hasDigit = /[0-9]/.test(password);
  const hasSpecialChar = /[\W_]/.test(password);  // \W matches any non-word character, _ is for underscore

  let status = true;
  let message = "Password is strong";

  if (password.length < minLength) {
      status = false;
      message = "Password must be at least 8 characters long.";
  } else if (!hasUpperCase) {
      status = false;
      message = "Password must contain at least one uppercase letter.";
  } else if (!hasLowerCase) {
      status = false;
      message = "Password must contain at least one lowercase letter.";
  } else if (!hasDigit) {
      status = false;
      message = "Password must contain at least one numerical digit.";
  }

  return {
      status: status,
      message: message
  };
}

export function validateEthiopianPhoneNumber(phoneNumber) {
  // Define the regular expression for the specific format: 9XXXXXXXX
  const pattern = /^9\d{8}$/;

  // Check if the phone number matches the pattern
  if (pattern.test(phoneNumber)) {
      return { isValid: true, message: "Valid Ethiopian phone number." };
  }

  // Determine the error reason if the phone number is invalid
  if (!phoneNumber.startsWith('9')) {
      return { isValid: false, message: "Phone number must start with '9'." };
  }
  if (phoneNumber.length !== 9) {
      return { isValid: false, message: "Phone number must have exactly 9 digits after the leading '9'." };
  }

  return { isValid: false, message: "Invalid phone number format." };
}
export const logout=async(type)=>{
  
  try {
    await api.post("/Logout/user");

    // Clear access token from local storage
    localStorage.removeItem('Token');
    localStorage.removeItem('Member_account');
    localStorage.removeItem('Admin_user_account');
    

    // Remove Authorization header
    delete api.defaults.headers.common['Authorization'];

    console.log('User logged out');
   if(type)
    { window.location.href=("/Loginpage");}
   else
  {
    window.location.href=("/Admin/login");
  }
  } catch (error) {
    console.error('Error logging out:', error);
  }


}